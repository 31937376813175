import React from "react"
// import { Link } from "gatsby"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"

const Privacy = ({ location }) => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="隐私政策"
        pagepath={location.pathname}
        pagedesc="隐私政策 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <Breadcrumb title="隐私政策" />
      <main className="policy">
        <section className="policy__section l-container">
          <h1 className="policy__title">隐私政策</h1>
          <p className="align-R">
            颁布日期 2021年12月1日
            <br />
            最后修订日期 2021年12月1日
            <br />
            <br />
          </p>
          <p className="policy__text">
            　X-HACK公司（以下简称'公司'）从事支持电子商务网站的建设/引进和IT系统的承包开发业务，并认识到保护客户、商业伙伴、雇员和相关人员的个人信息是一项严肃的责任。
            因此，基于我们的隐私理念和为自己制定的行为准则，我们充分认识到我们的社会使命，并遵守保护个人权利和有关个人数据的法律和法规。
            <br />
            　此外，我们在此声明，我们已经建立了一个个人数据保护管理系统，以实现以下政策，整个公司致力于持续改进，始终了解IT技术的最新趋势、社会需求的变化和商业环境的波动。
          </p>
          <h2 className="policy__title2">隐私政策</h2>
          <ol className="policy__olist">
            <li className="policy__item">
              本公司将适当地获取、使用和提供个人信息，对个人信息的处理不会超出实现特定使用目的的必要范围。
              我们还将采取措施，确保。
            </li>
            <li className="policy__item">
              本公司遵守有关处理个人数据的法律、国家准则和其他规范。
            </li>
            <li className="policy__item">
              为防止个人信息泄露、丢失或损坏等风险，公司采取合理的安全措施，注入与业务实际情况相匹配的管理资源，不断完善个人信息的安全体系。
              一旦发生事故，将立即采取纠正措施。
            </li>
            <li className="policy__item">
              本公司将对有关个人数据处理的投诉和咨询作出迅速和真诚的回应。
            </li>
            <li className="policy__item">
              个人数据保护管理系统将根据环境的变化和公司的实际情况，及时和适当地进行审查和不断改进。
            </li>
          </ol>
          <p className="align-R">Shinsuke Matsuda，X-HACK公司代表董事。</p>

          <div className="policy__revision">
            <h2 className="policy__title2">
              [关于隐私政策的查询以及关于个人信息的投诉和咨询的联系方式]
            </h2>
            <p className="policy__text">
              　　　　日本东京都港区南青山3-15-9MINOWA表参道3楼-251，107-0062。
              <br />
              　　　　X-HACK公司 关于个人信息的查询
              <br />
              　　　　电子邮件地址：smatsuda@x-hack.jp
            </p>
          </div>

          <h2 className="policy__title2">公布个人数据的使用目的</h2>
          <p className="policy__text">
            　X-HACK公司（以下简称
            "公司"）根据《个人资料保护法》（《个人资料保护法》）第18.1条宣布，公司将在以下使用目的范围内使用个人资料。
          </p>
          <h3 className="infotmation__title3">使用个人数据的目的</h3>
          <p className="policy__text">
            本公司处理个人数据的目的如下
            <br />
            ・提供有关我们产品和服务的信息
            <br />
            ・为产品和服务提供适当的售后服务
            <br />
            ・对我们的各种业务进行查询的人的个人信息被用来回应这些查询
            <br />
            ・我们员工的个人资料，用于人事管理、工作管理、健康管理等
            <br />
            ・採用業務、応募者との連絡のため
            <br />
            ・PARKLOT用户的个人数据用于服务的提供和管理以及计费目的
            <br />
            ・PARKLOT用户的个人信息被用来提供这项服务
          </p>

          <h2 className="policy__title2">传播所拥有的个人数据的相关事宜</h2>
          <p className="policy__text">
            　X-HACK公司（以下简称
            "公司"）持有的个人数据（公司有权通知使用目的、披露、更正、添加或删除内容、停止使用、删除和停止向第三方提供）如下。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              负责个人数据的控制器的名称、隶属关系和联系方式
              <p className="policy__text">
                管理员姓名：Shinsuke Matsuda，个人数据保护经理
                <br />
                电子邮件地址：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              所持个人数据的使用目的
              <p className="policy__text">
                ・提供有关我们产品和服务的信息
                <br />
                ・为产品和服务提供适当的售后服务。
                <br />
                ・对我们的各种业务进行查询的人的个人信息被用来回应这些查询
                <br />
                ・我们员工的个人资料，用于人事管理、工作管理、健康管理等
                <br />
                ・用于招聘和与申请人沟通
              </p>
            </li>
            <li className="policy__item">
              对所持个人数据进行投诉的联系人
              <p className="policy__text">
                有关本公司持有的个人数据的查询，可通过以下联络点进行。
                <br />
                　　　　日本东京都港区南青山3-15-9MINOWA表参道3楼-251，107-0062。
                <br />
                　　　　X-HACK公司 关于个人信息的查询
                <br />
                　　　　电子邮件地址：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              关于我们所加入的授权的个人数据保护组织
              <p className="policy__text">
                没有授权的个人数据保护组织与本公司有联系。
              </p>
            </li>
          </ol>

          <h2 className="policy__title2">披露保留的个人数据的程序</h2>
          <p className="policy__text">
            对于保留的个人数据，你可以要求
            "通知使用目的、披露、更正、增加或删除内容、停止使用、删除和停止向第三方提供"（披露等）。
            <br />
            　本公司将根据以下程序对披露个人信息的请求作出回应。
          </p>

          <ol className="policy__olist">
            <li className="policy__item">
              接收披露请求等
              <p className="policy__text">
                (1) 请联系以下地址
                <br />
                本公司将向您邮寄一份 "规定表格"，到达后，在同意本公司的
                "个人信息处理 "后，请将表格邮寄或带到以下地址。
                <br />
                　　　　日本东京都港区南青山3-15-9MINOWA表参道3楼-251，107-0062。
                <br />
                　　　　X-HACK公司 关于个人信息的查询
                <br />
                　　　　电子邮件地址：smatsuda@x-hack.jp
                <br />
                <br />
                (2) 为了确认身份，请将以下文件之一连同(1) "规定的表格
                "一起邮寄或亲自携带。
                <br />
                ・证实提出披露请求者身份的官方身份证明文件，如驾驶执照或护照的复印件等（该文件必须显示提出请求者的姓名和地址）。
                <br />
                ・∙ 居住证明的复印件（在申请公开之日前30天内制作）。
                <br />
                <br />
                (3)如果程序是由代表进行的，除了(1) "规定的表格 "和(2)
                "身份证明文件 "外，还请发送或携带以下文件之一(a)和(b)。
                <br />
                a) 代理人的身份文件。
                <br />
                ・证明代理人的文件，代理人的驾照、护照或其他可用于核实代理人的官方身份证明的副本（显示提出披露请求的代理人的姓名和地址）。
                <br />
                ・代理人的居住证明副本（在申请披露日期前30天内制作）。
                <br />
                ・如果代表是律师，请提供显示其注册号码的文件。
                <br />
                b) 证明代表权的文件(如委托书)。
                <br />
                ・如果文件证明了个人或其代表的身份，居住地被清楚地标明，那么它可以被删除。
                <br />
                ・证明个人或其代表身份的文件将在公司获得后的两个月内负责任地予以处理。
              </p>
            </li>
            <li className="policy__item">
              "披露请求 "的费用以及如何收取
              <p className="policy__text">
                只有在要求通知或披露使用目的的情况下，才会按要求收取以下金额（包括我们的答复费用）。
                在邮寄申请表格时，请附上以下金额的邮政定额汇票。
                <br />
                ・费用金额：1,000日元（含税）。
              </p>
            </li>
            <li className="policy__item">
              研究结果的交流
              <p className="policy__text">
                我们的内部审查结果将毫不拖延地通过以下方式进行通报。
                <br />
                ・在通知或披露个人信息使用目的的情况下，会邮寄或亲自送达一封信
                <br />
                ・如需更正、通知或删除个人数据、暂停使用、删除或暂停向第三方提供，请致电或发送电子邮件
                <br />
                <br />
                该信应通过以下适当方式之一发送。
                <br />
                ・回复信会邮寄到当事人的注册地址。
                <br />
                ・回复文本将通过电子邮件发送到该人的注册电子邮件地址。
                <br />
                ・应按其登记的电话号码致电该人，并口头回答。
              </p>
            </li>
          </ol>

          <h2 className="policy__title2" id="privacy_policy">
            个人数据的处理
          </h2>
          <p className="policy__text">
            　我们根据JIS
            Q15001:2017的A.3.4.2.5（A.3.4.2.4，直接从当事人书面获得信息时的措施）收集和保存个人信息。
            <br />
            　从这个输入表格中获得的个人数据将只用于以下第3节所述的目的，不会超出这些目的的范围。
          </p>
          <ol className="policy__olist">
            <li className="policy__item">
              企业名称
              <p className="policy__text">X-HACK公司</p>
            </li>
            <li className="policy__item">
              负责个人数据的控制人的姓名、隶属关系和联系方式
              <p className="policy__text">
                个人数据保护经理：Shinsuke Matsuda
                <br />
                电子邮件地址：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              使用个人数据的目的
              <p className="policy__text">・对查询作出回应</p>
            </li>
            <li className="policy__item">
              向第三方提供个人数据
              <p className="policy__text">
                本公司不会向第三方披露或提供您提供的个人信息，但以下情况除外
                <br />
                ・在当事人同意的情况下
                <br />
                ・在法律要求的情况下
                <br />
                ・为保护一个人的生命、身体或财产而必须这样做，并且难以获得该人的同意
                <br />
                ・当它对改善公共卫生或促进儿童的健康发展特别必要，并且难以获得有关人员的同意
                <br />
                ・当有必要与国家机构、地方当局或受此类机构委托执行法律规定的事务的人进行合作，而获得该人的同意可能会妨碍此类事务的执行时
              </p>
            </li>
            <li className="policy__item">
              外包处理个人数据
              <p className="policy__text">
                我们不会将您提供的个人数据的处理外包出去。
              </p>
            </li>
            <li className="policy__item">
              请求披露个人信息
              <p className="policy__text">
                客户可以联系本公司的
                "个人信息咨询中心"，要求披露其个人信息（通知使用目的、披露、更正、增加或删除内容、停止使用或删除、停止向第三方提供）。
                在这种情况下，公司将在确认有关人员的身份后在合理的时间内作出回应。
                如需了解有关披露请求的更多信息，请联系以下个人信息联系人。
                <br />
                <br />
                　　　　日本东京都港区南青山3-15-9MINOWA表参道3楼-251，107-0062
                <br />
                　　　　X-HACK公司 关于个人信息的查询
                <br />
                　　　　电子邮件地址：smatsuda@x-hack.jp
              </p>
            </li>
            <li className="policy__item">
              提供个人数据的自愿性
              <p className="policy__text">
                你是否向我们提供你的个人数据是自愿的。
                但是，如果你不提供所需的项目，我们可能无法在适当的状态下提供各项服务等。
              </p>
            </li>
            <li className="policy__item">
              因访问本网站而以机械方式获得的信息。
              <p className="policy__text">
                本公司可通过cookies获取访问者的信息，以确保所访问网站的安全性，并改善用户服务。
              </p>
            </li>
          </ol>
        </section>
      </main>
    </Layout>
  )
}

export default Privacy
